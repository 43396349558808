/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/scss/main.scss';
import React from "react"
import { SubPageProvider } from "./src/context/SubPageContext"
export const wrapRootElement = ({ element }) => (
  <SubPageProvider>{element}</SubPageProvider>
)
