import React, {useState} from 'react';

const defaultState = {
  project: 'cellular-copper',
  changeProject: () => {},
  people: 'current-members',
  changePeople: () => {}
}
const SubPageContext = React.createContext(defaultState);

const SubPageProvider = ({children}) => {

  const [project, setProject] = useState('cellular-copper');

  const changeProject = projectId => {
    setProject(projectId);
  }

  const [people, setPeople] = useState('current-members');

  const changePeople = peopleId => {
    setPeople(peopleId);
  }

  return (
    <SubPageContext.Provider
      value={{
        project,
        changeProject,
        people,
        changePeople
      }}
    >
      {children}
    </SubPageContext.Provider>
  )

}


export default SubPageContext
export { SubPageProvider }